/* -----------------------------------------------  common css  ------------------------------------------------------ */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
}

input:focus{
    outline: none;
}

a{
    text-decoration: none;
}

ul{
    list-style-type: none;
}

.row{
    width: 100%;
}

:root {
    --Poppins: "Poppins", sans-serif !important;
  }

  body{
    overflow-x: hidden;
  }
  
  /* body{
    overflow-y: scroll;
    scroll-behavior: smooth;
  }

  html , #root { 
    overflow-y: scroll;
    scroll-behavior: smooth;
   } */

body::-webkit-scrollbar{
    width: 7px;
    background: transparent;
    border-radius: 50px;
    scroll-behavior: smooth;
}

body::-webkit-scrollbar-track{
    width: 7px;
    background: transparent;
    border-radius: 50px;
}

body::-webkit-scrollbar-thumb{
    width: 7px;
    background: #63bbc5;
    border-radius: 50px;
}


section:target {
    /* position: sticky;
    top: 50px; */
}



/* -----------------------------------------------  header css  ------------------------------------------------------ */

.header_main_section{
    height: 100px !important;
    background: #B1F1FA !important;
    padding: 10px 20px !important;
    position: sticky;
    top: 0;
    z-index: 100;
    margin-bottom: 0 !important;
}

.header_main_section{
    display: flex;
    align-items: center;
    height: 100%;
}

.header_main_section .logo{
    width: 315px;
    height: 105px;
}

.header_main_section .logo > img{
    width: 100%;
    height: 100%;
}

.header_main_section     .offcanvas-body ul{
    display: flex;
    /* margin: auto; */
    margin-bottom: 0;
    margin-left: auto;
}

.header_main_section .offcanvas-body ul > li {
    margin: 0px 38px;
}

.header_main_section .offcanvas-body>ul>li>a {
    color: #157FBB;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    transition: all 0.5s ease;
    position: relative;
}

.header_main_section .offcanvas-body>ul>li>a::before {
    content: "";
    position: absolute;
    width: 0%;
    height: 2px;
    background: #157FBB;
    bottom: 0;
    transition: all 0.5s ease;
    left: 50%;
    transform: translate(-50%, 0);
    visibility: visible;
}

.header_main_section .offcanvas-body > ul > li a:hover{
    transform: scale(1.2);
    /* border-bottom: 2px solid white; */
    color: #02485E;
    display: inline-block;
    font-weight: 600;
    font-style: normal;
    transition: all 0.5s ease;
}

.header_main_section .offcanvas-body > ul > li a:hover::before{
    /* content: "";
    position: absolute; */
    width: 100%;
    /* height: 2px;
    background: red;
    bottom: 0; */
    transition: all 0.5s ease;
    /* animation: myfirst .3s infinite ; */
    /* left: 50%;
    transform: translate(-50%, 0); */
}
/* 
@keyframes myfirst {
    0%   {width:0%;}
    25%  {width:50%;}
    50%  {width:100%;}
    75%  {width:50%;}
    100% {width:0%;} 
    from {
        width: 0%;
    }
    to{
        width: 100%;
    }
} */

/* .header_main_section .offcanvas-body > ul > li .active{
    color: #02485E;
    font-weight: 600;
    font-style: normal;
} */

.header_main_section .offcanvas-body > button{
    border-radius: 10px;
    background: linear-gradient(270deg, #37D5C1 0%, #32BAEC 100%);
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    color: #FFF;
    padding: 9px 16px 11px 16px;
    border: none;
    /* margin-left: auto; */
}
 
.offcanvas_header.show .offcanvas-body ul{
    display: flex;
    flex-direction: column;
    /* margin: auto; */
    margin-bottom: 0;
    margin-left: auto;
    padding: 0 10px 0px 20px;
}
.offcanvas_header.show .offcanvas-body ul > li {
    margin: 0px 0px;
    margin-bottom: 10px;
}
.offcanvas_header.show .offcanvas-body>ul>li>a {
    color: #157FBB;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    transition: all 0.5s ease;
}

/* HEADER STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-header {
    /* position: relative; */
    top: 0;
    right: 0;
    left: 0;
    padding: 20px 0;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.15);
    transition: all 0.3s;
  }


  /* STICKY STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
  .page-header.is-sticky {
    position: fixed;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);
    padding: 8px 0;
    backdrop-filter: blur(10px);
    animation: slideDown 0.3s linear;
    transition: all 0.3s;
    z-index: 5000;
  }
  
  @keyframes slideDown {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }

/* -----------------------------------------------  about us css  ------------------------------------------------------ */

.carousel_item_main{
    height: 600px;
    border: 1px solid #81D9FF;
background: linear-gradient(257deg, rgba(80, 176, 179, 0.70) 0%, rgba(149, 146, 146, 0.40) 100%);
backdrop-filter: blur(9.5px);
}

.carousel_item_row{
    height: 100%;
    align-items: center;
    width: 100%;
    margin: auto;
}

.carousel_item_row .carousel_col{
    height: 100%;
    padding: 0;
}

.carousel_item_main .carousel_main_left{
    padding-left: 70px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
}

.carousel_item_main .carousel_main_left > h5{
    color: #02485E;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 40px;
    font-family: var(--Poppins);
    line-height: 91px;
    width: 94%;
    text-align: left;

}

.carousel_item_main .carousel_main_left > button{
    color: white;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 20px;
    font-family: var(--Poppins);
    border-radius: 50px;
    background: linear-gradient(90deg, #32BAEB 0%, #37D5C2 100%);
    padding: 10px 35px;
    border: none;
    cursor: pointer;
}

.carousel_main_right{
    display: flex;
    justify-content: center;
    height: 100%;
}

.carousel_main_right > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
    border-top-left-radius: 38px;
    border-bottom-left-radius: 38px;
    margin-left: auto;
    object-position: center;
}

.carousel_main_top .carousel-control-prev , .carousel_main_top .carousel-control-next {
    display: none;
}

.carousel_main_top .carousel-indicators button{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #DFF9FA;
    position: relative;
}

.carousel_main_top .carousel-indicators button.active::before{
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #02485E;
    /* transform: translate(-50%, -50%); */
}
/* -----------------------------------------------  about us css  ------------------------------------------------------ */

#AboutUs{
    margin-top: 90px;
}

.aboutUs_row{
    margin: auto;
    margin-bottom: 70px;
}

.aboutUs_left{
    padding-left: 70px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.aboutUs_left > h6{
    color: #32B9EC;
    font-size: 26px;
    font-family: var(--Poppins);
  font-weight: 500;
  font-style: normal;
  margin-bottom: 25px;
}

.aboutUs_left > h3{
    color: #02485E;
    font-size: 28px;
font-style: normal;
font-weight: 700;
margin-bottom: 25px;
}

.aboutUs_left > p.description_long{
    color: #5A5A5A;
    font-size: 21px;
font-style: normal;
font-weight: 500;
width: 90%;
margin-bottom: 25px;
}

.aboutUs_left > p.description_short{
    color: #000000;
    font-size: 20px;
font-style: normal;
font-weight: 500;
margin-bottom: 25px;
}

.aboutUs_left > button{
    border: none;
    border-radius: 50px;
background: linear-gradient(90deg, #32BAEB 0%, #37D5C2 100%);
color: white;
font-size: 20px;
font-family: var(--Poppins);
  font-weight: 500;
  font-style: normal;
  width: 173px;
  height: 45px;
}

.aboutUs_right{
    height: 100%;
    display: flex;
    align-items: center;
}

.aboutUs_right > .img_div {
    position: relative;
    height: max-content;
    display: flex;
    justify-content: flex-end;
    /* padding: 101px 0 40px; */
    padding: 0px 0 40px;
    width: 85%;
}

.aboutUs_right > .img_div > img{
    width: 100%;
}

.aboutUs_right > .img_div > .quation_card{
    clear: both;
    width: max-content;
    padding: 30px 35px 36px 35px;
    position: absolute;
    bottom: -181px;
    left: -139px;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 22px;
background: #FFF;
box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.25);
}

.aboutUs_right > .img_div > .quation_card > .Quotesicon{
    font-size: 27px;
    color: #32B9EC;
    border: 2px solid #32B9EC;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-bottom: 14px;
    transition: all 0.3s linear;
}

.aboutUs_right > .img_div > .quation_card:hover > .Quotesicon{
    background: #32B9EC;
    color: #ffffff;
    transition: all 0.3s linear;
}

.aboutUs_right > .img_div > .quation_card > ul{
    padding: 0;
    margin: 0;
}

.aboutUs_right > .img_div > .quation_card > ul > li{
    color: #000000;
    font-size: 17px;
font-style: normal;
font-weight: 500;
font-family: var(--Poppins);
line-height: 30px;
}


/* -----------------------------------------------  video section css  ------------------------------------------------------ */

.video_main{
    width: 100%;
    height: 600px;
    margin-top: 250px;
    position: relative;
    background: url('./components/assets/images/video_background_img_new1.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
}

/* .video_main::before{
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0.3;
} */

.video_main .playbutton_main{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.video_main .play_btn{
    width: 90px;
    height: 90px;
    background: white;
    color: #32b9ec;
    font-size: 67px;
    border: none;
    z-index: 5;
    position: relative;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-left: 5px; */
}

.video_main .play_btn:hover{
    background: #32b9ec;
    color: #ffffff;
}

.video_main .play_btn::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    border: 1px solid white;
    -webkit-animation: ripple 1s linear infinite;
    animation: ripple 1s linear infinite;
}

@keyframes ripple{
    0% {
        transform: scale(1);
    }
    75% {
        transform: scale(1.75);
        opacity: 1;
    }
    100% {
        transform: scale(2);
        opacity: 0;
    }
}


/* -----------------------------------------------  KIOSK section css  ------------------------------------------------------ */


.Kiosk_row{
    margin: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    background: linear-gradient(106deg, #C2F8FF -5.91%, #50ABB7 97.46%);
    box-shadow: 0px -15px 124px 0px rgba(2, 145, 154, 0.30) inset;
}

.Kiosk_left{
    padding-left: 70px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Kiosk_left > h6{
    color: #02485E;
    font-size: 36px;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 25px;
}

.Kiosk_left > p.para_long{
    color: #246275;
    font-size: 23px;
font-style: normal;
font-weight: 500;
width: 100%;
margin-bottom: 25px;
}

.Kiosk_left > p.para_short{
    color: #246275;
    font-size: 23px;
font-style: normal;
font-weight: 500;
margin-bottom: 25px;
}

.Kiosk_right > .img_div {
    position: relative;
    height: max-content;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.Kiosk_right > .img_div > img {
    width: 100%;
}


/* -----------------------------------------------  Homekit section css  ------------------------------------------------------ */


.Homekit_row{
    margin: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    background: linear-gradient(106deg, #50ABB7 0%, #C2F8FF 107.76%);
box-shadow: 0px -15px 124px 0px rgba(2, 145, 154, 0.30) inset;
}

.Homekit_right{
    padding-left: 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-right: 50px;

}

.Homekit_right > h6{
    color: #02485E;
    font-size: 36px;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 25px;
}

.Homekit_right > p.para_long{
    color: #246275;
    font-size: 23px;
font-style: normal;
font-weight: 500;
width: 100%;
margin-bottom: 25px;
}

.Homekit_right > p.para_short{
    color: #246275;
    font-size: 23px;
font-style: normal;
font-weight: 500;
margin-bottom: 25px;
}

.Homekit_left > .img_div {
    position: relative;
    height: 530px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.Homekit_left > .img_div > img {
    width: 100%;
}


/* -----------------------------------------------  moblie application section css  ------------------------------------------------------ */



.moblie_application_main{
    margin: auto;
    padding-top: 100px;
    padding-bottom: 70px;
    background: linear-gradient(106deg, #C2F8FF -5.36%, #50ABB7 106.47%);
box-shadow: 0px -15px 124px 0px rgba(2, 145, 154, 0.30) inset;
text-align: center;
}

.moblie_main_title{
    color: #000000;
    font-size: 36px;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 25px;
}

.moblie_main_description{
    color: #246275;
    font-size: 22px;
  font-weight: 500;
  font-style: normal;
  margin-bottom: 5px;
}

.moblie_application_row{
    margin-top: 70px;
    align-items: center;
}

.moblie_application_left{
    padding-left: 70px;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    align-items: flex-start;
}

.moblie_application_left > h6{
    color: #02485E;
    font-size: 36px;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 25px;
  text-align: left;
}

.moblie_application_left > p.mobile_para_long{
    color: #246275;
    font-size: 24px;
font-style: normal;
font-weight: 500;
width: 100%;
margin-bottom: 25px;
text-align: left;
}

.moblie_application_right  {
    position: relative;
    height: 530px;
    display: flex;
    justify-content: flex-end;
    margin: auto;
}

.moblie_application_right > img{
    position: relative;
    top: 70px;
    left: 0px;
    z-index: 10;
    width: 100%;
}

.moblie_application_secondrow {
    width: 100%;
    margin: auto;
}
.moblie_application_secondrow  .moblie_application_left {
    margin-top: 50px;
}

/* .moblie_application_secondrow  .moblie_application_right {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    align-items: flex-end;
}

.moblie_application_secondrow  .moblie_application_right > img{
    position: absolute;
    left: 53%;
    bottom: 0;

} */


/* -----------------------------------------------  smart kisok section css  ------------------------------------------------------ */


.smart_kisok_row{
    margin: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    background: linear-gradient(106deg, #50ABB7 0%, #C2F8FF 107.76%);
box-shadow: 0px -15px 124px 0px rgba(2, 145, 154, 0.30) inset;
}

.smart_kisok_col_img , .kiosk_col_img{
    display: flex;
    align-items: center;
    justify-content: center;
}

.smart_kisok_right{
    padding-left: 0px;
    padding-right: 50px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.smart_kisok_right > h6{
    color: #02485E;
    font-size: 36px;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 25px;
}

.smart_kisok_right > p.para_long{
    color: #246275;
    font-size: 23px;
font-style: normal;
font-weight: 500;
width: 100%;
margin-bottom: 25px;
}

.smart_kisok_right > p.para_short{
    color: #246275;
    font-size: 23px;
font-style: normal;
font-weight: 500;
margin-bottom: 25px;
}

.smart_kisok_left > .img_div {
    position: relative;
    height: 530px;
    display: flex;
    justify-content: flex-start;
    width: 99%;
}

.smart_kisok_left > .img_div > img{
    width: 100%;
}


/* -----------------------------------------------  smart watch section css  ------------------------------------------------------ */


.smart_watch_row{
    margin: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    background: linear-gradient(106deg, #C2F8FF -5.91%, #50ABB7 97.46%);
    box-shadow: 0px -15px 124px 0px rgba(2, 145, 154, 0.30) inset;
}

.smart_watch_left{
    padding-left: 70px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.smart_watch_left > h6{
    color: #02485E;
    font-size: 36px;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 25px;
}

.smart_watch_left > p.para_long{
    color: #246275;
    font-size: 23px;
font-style: normal;
font-weight: 500;
width: 100%;
margin-bottom: 25px;
}

.smart_watch_right > .img_div {
    position: relative;
    height: max-content;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.smart_watch_right > .img_div > img{
    width: 100%;
}



/* -----------------------------------------------  our service section css  ------------------------------------------------------ */


.our_service_row{
    margin: auto;
    padding-top: 70px;
    padding-bottom: 70px;
    background: #A5F5FA;
box-shadow: 0px -15px 124px 0px rgba(2, 145, 154, 0.30) inset;
height: 600px;
position: relative;
}

.our_service_row::before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 400px;
    height: 100%;
    background: url('./components/assets/images/our_Service_dorctor_image.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

.our_service_main{
    padding: 60px;
    border-radius: 46px;
    background: linear-gradient(92deg, rgba(255, 255, 255, 0.45) 0.96%, rgba(103, 189, 226, 0.35) 99.05%);
    backdrop-filter: blur(5px);
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.our_service_row h6{
    color: #02485E;
    font-size: 25px;
font-style: normal;
font-weight: 500;
width: 100%;
margin-bottom: 25px;
}

.our_service_row p{
    color: #246275;
    font-size: 32px;
font-style: normal;
font-weight: 500;
width: 100%;
margin-bottom: 25px;
line-height: 50px;
}


/* -----------------------------------------------  Stethoscope section css  ------------------------------------------------------ */


.Stethoscope_row{
    margin: auto;
    padding-top: 111px;
    /* padding-bottom: 120px; */
    padding-bottom: 70px;
    background: linear-gradient(290deg, #BDF7FF 34.85%, #81C4CD 98.61%);
box-shadow: 0px -3px 61px 0px rgba(2, 145, 154, 0.30) inset;
position: relative;
}

.Stethoscope_row > .Stethoscope_col {
    z-index: 50;
    display: flex;
    align-items: flex-end;
}

.Stethoscope_row::before{
    content: "";
    position: absolute;
    top: 0;
    /* background: linear-gradient(to bottom, #ACA5FA, #5D0C84); */
    background: url('./components/assets/images/triangle_image_full.svg');
    height: 100%;
    width: 100%;
    /* clip-path: polygon(50% 0%, 0% 100%, 100% 100%); */
    /* transform: rotate(353deg); */
    left: 0px;
    /* top: -85px; */
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
}

.Stethoscope_right{
    padding-left: 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    /* right: 30px; */
    position: relative;
    padding-left: 50px;
    padding-right: 50px;
}

.Stethoscope_right > h6{
    color: #02485E;
    font-size: 36px;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 25px;
}

.Stethoscope_right > p.para_long{
    color: #246275;
    font-size: 23px;
font-style: normal;
font-weight: 500;
width: 100%;
margin-bottom: 25px;
}

.Stethoscope_left > .img_div {
    position: relative;
    height: 535px;
    display: flex;
    justify-content: flex-start;
    left: 0px;
    width: 100%;
    margin: auto;
    bottom: 70px;
}

.Stethoscope_left > .img_div > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.Stethoscope_left > .img_div::before{
    content: "";
    position: absolute;
    width: 37px;
    height: 65px;
    background: #000;
    filter: blur(35px);
    z-index: 5;
    bottom: -70px;
    left: 45%;
    display: none;
}


/* -----------------------------------------------  Otoscope section css  ------------------------------------------------------ */


.Otoscope_row{
    margin: auto;
    padding-top: 100px;
    padding-bottom: 200px;
    background: linear-gradient(290deg, #BDF7FF 34.85%, #81C4CD 98.61%);
box-shadow: 0px -3px 61px 0px rgba(2, 145, 154, 0.30) inset;
position: relative;
}

.Otoscope_row > .otoscope_col {
    z-index: 50;
}

.Otoscope_row::before{
    content: "";
    position: absolute;
    top: 0;
    /* background: linear-gradient(to bottom, #ACA5FA, #5D0C84); */
    background: url('./components/assets/images/triangle_image_full2.svg');
    height: 100%;
    width: 100%;
    /* clip-path: polygon(50% 0%, 0% 100%, 100% 100%); */
    /* transform: rotate(353deg); */
    left: 0px;
    /* top: -85px; */
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
} 

.Otoscope_left{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    right: 30px;
    position: relative;
    padding-left: 70px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Otoscope_left > h6{
    color: #02485E;
    font-size: 36px;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 25px;
}

.Otoscope_left > p.para_long{
    color: #246275;
    font-size: 23px;
font-style: normal;
font-weight: 500;
width: 100%;
margin-bottom: 25px;
}

.Otoscope_right{
    /* overflow: hidden; */
    position: relative;
}

.Otoscope_right > .img_div {
    position: relative;
    height: 400px;
    display: flex;
    justify-content: flex-end;
    left: 0;
    overflow: hidden;
}

.Otoscope_right::before{
    content: "";
    position: absolute;
    width: 176px;
    height: 19px;
    background: #000;
    filter: blur(38px);
    z-index: 5;
    bottom: -30px;
    left: 94px;
    z-index: 10;
    display: none;
}

.Otoscope_right > .img_div  > img{
    top: -3px;
    position: relative;
    object-fit: contain;
    width: 100%;
}



/* -----------------------------------------------  body temperature section css  ------------------------------------------------------ */


.body_temperature_row{
    margin: auto;
    padding-top: 170px;
    /* padding-bottom: 130px; */
    background: linear-gradient(290deg, #BDF7FF 34.85%, #81C4CD 98.61%);
box-shadow: 0px -3px 61px 0px rgba(2, 145, 154, 0.30) inset;
position: relative;
overflow: hidden;
}

.body_temperature_row::before{
    content: "";
    position: absolute;
    top: 0;
    /* background: linear-gradient(to bottom, #ACA5FA, #5D0C84); */
    background: url('./components/assets/images/triangle_image_full.svg');
    height: 100%;
    width: 100%;
    /* clip-path: polygon(50% 0%, 0% 100%, 100% 100%); */
    /* transform: rotate(353deg); */
    left: 0px;
    /* top: -85px; */
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
}

/* .body_temperature_row::after{
    content: "";
    position: absolute;
    top: 0;
    background: url('./components/assets/images/triangle_image_full.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    height: 100%;
    width: 25%;
    right: -213px;
    z-index: 3;
} */

.body_temperature_right{
    padding-left: 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 78%;
    right: 0px;
    position: relative;
    padding-left: 50px;
    padding-right: 50px;
}

.body_temperature_right > h6{
    color: #02485E;
    font-size: 36px;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 25px;
}

.body_temperature_right > p.para_long{
    color: #246275;
    font-size: 23px;
font-style: normal;
font-weight: 500;
width: 100%;
margin-bottom: 25px;
}

.body_temperature_left {
    height: 100%;
}

.body_temperature_left > .img_div {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    top: 0;
    /* width: max-content; */
    left: 0;
}

.body_temperature_left > .img_div > img {
    width: 100%;
    height: 555px;
    object-fit: contain;
}



/* -----------------------------------------------  blood oxygen section css  ------------------------------------------------------ */


.blood_oxygen_row {
    margin: auto;
    padding-top: 100px;
    padding-bottom: 200px;
    background: linear-gradient(290deg, #BDF7FF 34.85%, #81C4CD 98.61%);
box-shadow: 0px -3px 61px 0px rgba(2, 145, 154, 0.30) inset;
position: relative;
}

.blood_oxygen_row > .blood_oxygen_col {
    z-index: 50;
}

.blood_oxygen_row::before{
    content: "";
    position: absolute;
    top: 0;
    /* background: linear-gradient(to bottom, #ACA5FA, #5D0C84); */
    background: url('./components/assets/images/triangle_image_full2.svg');
    height: 100%;
    width: 100%;
    /* clip-path: polygon(50% 0%, 0% 100%, 100% 100%); */
    /* transform: rotate(353deg); */
    left: 0px;
    /* top: -85px; */
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
} 

.blood_oxygen_left{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    right: 30px;
    position: relative;
    padding-left: 70px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.blood_oxygen_left > h6{
    color: #02485E;
    font-size: 36px;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 25px;
}

.blood_oxygen_left > p.para_long{
    color: #246275;
    font-size: 23px;
font-style: normal;
font-weight: 500;
width: 100%;
margin-bottom: 25px;
}

.blood_oxygen_right{
    /* overflow: hidden; */
    position: relative;
    display: flex;
    justify-content: flex-end;
}

.blood_oxygen_right > .img_div {
    position: relative;
    height: 400px;
    display: flex;
    justify-content: flex-end;
    left: 0;
    overflow: hidden;
    width: 100%;
}

.blood_oxygen_right > .img_div  > img{
        /* top: -3px; */
    /* position: relative; */
    /* object-fit: cover; */
    width: 84%;
    margin: auto;
    height: 100%;

}



/* -----------------------------------------------  glucometer_row section css  ------------------------------------------------------ */


.glucometer_row{
    margin: auto;
    padding-top: 170px;
    padding-bottom: 130px;
    background: linear-gradient(290deg, #BDF7FF 34.85%, #81C4CD 98.61%);
box-shadow: 0px -3px 61px 0px rgba(2, 145, 154, 0.30) inset;
position: relative;
overflow: hidden;
}

.glucometer_row::before{
    content: "";
    position: absolute;
    top: 0;
    /* background: linear-gradient(to bottom, #ACA5FA, #5D0C84); */
    background: url('./components/assets/images/triangle_image_full.svg');
    height: 100%;
    width: 100%;
    /* clip-path: polygon(50% 0%, 0% 100%, 100% 100%); */
    /* transform: rotate(353deg); */
    left: 0px;
    /* top: -85px; */
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
}

/* .glucometer_row::after{
    content: "";
    position: absolute;
    top: 0;
    background: url('./components/assets/images/triangle_image_full.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    height: 100%;
    width: 25%;
    right: -213px;
    z-index: 3;
} */

.glucometer_right{
    padding-left: 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    right: 0px;
    position: relative;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 100px;
    
}

.glucometer_right > h6{
    color: #02485E;
    font-size: 36px;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 25px;
}

.glucometer_right > p.para_long{
    color: #246275;
    font-size: 23px;
font-style: normal;
font-weight: 500;
width: 100%;
margin-bottom: 25px;
}

.glucometer_left {
    height: 100%;
}

.glucometer_left > .img_div {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    top: 0;
    /* width: max-content; */
    left: 0;
}

.glucometer_left > .img_div > img {
    width: 75%;
    height: 100%;
    object-fit: cover;
}


/* -----------------------------------------------  blood pressure section css  ------------------------------------------------------ */


.blood_pressure_row {
    margin: auto;
    padding-top: 100px;
    padding-bottom: 200px;
    background: linear-gradient(290deg, #BDF7FF 34.85%, #81C4CD 98.61%);
box-shadow: 0px -3px 61px 0px rgba(2, 145, 154, 0.30) inset;
position: relative;
}

.blood_pressure_row > .blood_pressure_col {
    z-index: 50;
}

.blood_pressure_row::before{
    content: "";
    position: absolute;
    top: 0;
    /* background: linear-gradient(to bottom, #ACA5FA, #5D0C84); */
    background: url('./components/assets/images/triangle_image_full2.svg');
    height: 100%;
    width: 100%;
    /* clip-path: polygon(50% 0%, 0% 100%, 100% 100%); */
    /* transform: rotate(353deg); */
    left: 0px;
    /* top: -85px; */
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
} 

.blood_pressure_left{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    right: 30px;
    position: relative;
    padding-left: 70px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.blood_pressure_left > h6{
    color: #02485E;
    font-size: 36px;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 25px;
}

.blood_pressure_left > p.para_long{
    color: #246275;
    font-size: 23px;
font-style: normal;
font-weight: 500;
width: 100%;
margin-bottom: 25px;
}

.blood_pressure_right{
    /* overflow: hidden; */
    position: relative;
    display: flex;
    justify-content: flex-end;
}

.blood_pressure_right > .img_div {
    position: relative;
    height: 440px;
    display: flex;
    justify-content: flex-end;
    left: 0;
    overflow: hidden;
    width: 100%;
}

.blood_pressure_right > .img_div  > img{
       /* top: -3px; */
    /* position: relative; */
    object-fit: contain;
    width: 85%;
    margin: auto;
    height: 100%;
    object-position: left;

}



/* -----------------------------------------------  height & weight section css  ------------------------------------------------------ */


.height_weight_row{
    margin: auto;
    padding-top: 170px;
    /* padding-bottom: 130px; */
    background: linear-gradient(290deg, #BDF7FF 34.85%, #81C4CD 98.61%);
box-shadow: 0px -3px 61px 0px rgba(2, 145, 154, 0.30) inset;
position: relative;
overflow: hidden;
}

.height_weight_row::before{
    content: "";
    position: absolute;
    top: 0;
    /* background: linear-gradient(to bottom, #ACA5FA, #5D0C84); */
    background: url('./components/assets/images/triangle_image_full.svg');
    height: 100%;
    width: 100%;
    /* clip-path: polygon(50% 0%, 0% 100%, 100% 100%); */
    /* transform: rotate(353deg); */
    left: 0px;
    /* top: -85px; */
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
}

/* .height_weight_row::after{
    content: "";
    position: absolute;
    top: 0;
    background: url('./components/assets/images/triangle_image_full.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    height: 100%;
    width: 25%;
    right: -213px;
    z-index: 3;
} */

.height_weight_right{
    padding-left: 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    right: 0px;
    position: relative;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 100px;
    
}

.height_weight_right > h6{
    color: #02485E;
    font-size: 36px;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 25px;
}

.height_weight_right > p.para_long{
    color: #246275;
    font-size: 23px;
font-style: normal;
font-weight: 500;
width: 100%;
margin-bottom: 25px;
}

.height_weight_left {
    height: 100%;
}

.height_weight_left > .img_div {
    position: relative;
    height: 600px;
    display: flex;
    justify-content: flex-start;
    top: 0;
    /* width: max-content; */
    left: 0;
}

.height_weight_left > .img_div > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* -----------------------------------------------  ECG section css  ------------------------------------------------------ */


.ecg_row {
    margin: auto;
    padding-top: 100px;
    /* padding-bottom: 200px; */
    background: linear-gradient(290deg, #BDF7FF 34.85%, #81C4CD 98.61%);
box-shadow: 0px -3px 61px 0px rgba(2, 145, 154, 0.30) inset;
position: relative;
}

.ecg_row > .ecg_col {
    z-index: 50;
}

.ecg_row::before{
    content: "";
    position: absolute;
    top: 0;
    /* background: linear-gradient(to bottom, #ACA5FA, #5D0C84); */
    background: url('./components/assets/images/triangle_image_full2.svg');
    height: 100%;
    width: 100%;
    /* clip-path: polygon(50% 0%, 0% 100%, 100% 100%); */
    /* transform: rotate(353deg); */
    left: 0px;
    /* top: -85px; */
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
} 

.ecg_left{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    right: 30px;
    position: relative;
    padding-left: 70px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ecg_left > h6{
    color: #02485E;
    font-size: 36px;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 25px;
}

.ecg_left > p.para_long{
    color: #246275;
    font-size: 23px;
font-style: normal;
font-weight: 500;
width: 100%;
margin-bottom: 25px;
}

.ecg_right{
    /* overflow: hidden; */
    position: relative;
    display: flex;
    justify-content: flex-end;
}

.ecg_right > .img_div {
    position: relative;
    height: 700px;
    display: flex;
    justify-content: flex-end;
    left: 0;
    overflow: hidden;
    width: 100%;
}

.ecg_right > .img_div  > img{
    top: -3px;
    position: relative;
    object-fit: contain;
    width: 100%;
}



/* ----------------------------------------------  work flow section css  ---------------------------------------------------------- */

.workFlow_main{
    padding: 80px 50px;
    background: linear-gradient(106deg, #C2F8FF -5.36%, #50ABB7 106.47%);
box-shadow: 0px -15px 124px 0px rgba(2, 145, 154, 0.30) inset;
text-align: center;
}

.workFlow_main > h6 {
    color: #02485E;
    font-size: 24px;
font-style: normal;
font-weight: 500;
width: 100%;
margin-bottom: 10px;
}

.workFlow_main > h5 {
    color: #000;
    font-size: 33px;
font-style: normal;
font-weight: 700;
width: 100%;
margin-bottom: 25px;
letter-spacing: 5px;
}

.workFlow_path{
    padding: 0;
counter-reset: section;
margin-top: 70px;
position: relative;
}

.workFlow_path::before{
    content: "";
    position: absolute;
    width: 60px;
    height: 54px;
    background: transparent;
    left: -60px;
    /* top: 35px; */
    top: 1.3%;
    border: none;
    border-bottom: 4px dashed #02485E;
}

/* .workFlow_path::after{
    content: "";
    position: absolute;
    width: 60px;
    height: 144px;
    background: transparent;
    right: 229px;
    bottom: -118px;
    border: none;
    border-right: 4px dashed #02485E;
} */

.workFlow_path > .flow_item{
    width: 50%;
    border-radius: 20px;
background: linear-gradient(93deg, rgba(28, 104, 113, 0.40) 0.79%, rgba(63, 184, 195, 0.65) 99.6%);
backdrop-filter: blur(28px);
padding: 25px;
margin-bottom: 30px;
display: flex;
align-items: flex-start;
position: relative;
z-index: 10;
}

.flow_item::before {
    counter-increment: section;
    content: counter(section);
    border-radius: 48px;
    background: #CBF9FF;
    width: 35px;
    min-width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #02485E;
font-size: 17px;
font-style: normal;
font-weight: 700;
line-height: 20px; 
position: relative;
bottom: 4px;
  }


  .flow_item > .flow_content_main{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
    margin-right: 10px;
  }

.flow_item > .flow_content_main > h6 {
    color: #CBF9FF;
font-size: 24px;
font-style: normal;
font-weight: 700;
text-align: left;
}

.flow_item > .flow_content_main > p {
    color: #CBF9FF;
font-family: Inter;
font-size: 17px;
font-style: normal;
font-weight: 500;
text-align: left;
}

.flow_item > .flow_content_main > p > a{
    color: #02485E;
}

  .workFlow_path > .flow_item:nth-child(even){
    margin-left: auto;
  }


  .workFlow_path > .flow_item:nth-child(odd)::after{
    content: "";
    position: absolute;
    background: url('./components/assets/images/flow_dotted_1.svg');
    background-repeat: no-repeat;
    background-size: contain;
    right: -358px;
    width: 358px;
    height: 151px;
    top: 46%;
    z-index: -2;
  }
  .workFlow_path > .flow_item:nth-child(even)::after{
    content: "";
    position: absolute;
    background: url('./components/assets/images/flow_dotted_2.svg');
    background-repeat: no-repeat;
    background-size: contain;
    left: -358px;
    width: 358px;
    height: 151px;
    top: 46%;
    z-index: -2;
  }

  .workFlow_path > .flow_item:last-child::after{
    background: url('./components/assets/images/flow_dotted_4.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 154px;
    left: 205px;
    bottom: -291px;
    top: initial !important;
    height: 292px;
  }


/* ----------------------------------------------  board member section css ---------------------------------------------------------- */

  .board_member_main_section{
    height: max-content;
    background: url('./components/assets/images/board_member_background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0 80px 0;
  }

  .member_card_first{
    width: 90%;
    margin: auto;
  }

  .member_card_first > h5{
    color: #02485E;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 20px;
margin-top: 100px;
text-align: center;
  }

  .member_card_main{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
    background: transparent;
  }

  .member_card{
    height: 180px;
    padding: 15px 15px 0;
    border-radius: 23px;
background: #FFF;
width: 30%;
margin: 0 40px;
display: flex;
align-items: center;
overflow: hidden;
justify-content: center;
  }

  .member_card > img{
    height: 100%;
    transition: all 0.3s ease-in-out;
  }

  .member_card:hover > img{
    /* transform: scale(1.2); */
    height: 110%;
    transition: all 0.3s ease-in-out;
  }

  .member_card > .member_card_content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* width: 100%; */
    margin-left: 25px;
  }

  .member_card > .member_card_content > h6 {
    color: #02485E;
font-size: 25px;
font-style: normal;
font-weight: 700;
line-height: 20px;
  }

  .member_card > .member_card_content > p {
    color: #02485E;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px;
  }

  .member_card_secord{
    height: max-content;
    background: linear-gradient(91deg, rgba(255, 255, 255, 0.35) 0%, rgba(103, 189, 226, 0.45) 100%);
backdrop-filter: blur(5px);
margin-top: 120px;
text-align: center;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 50px 0;
  }

  .member_card_secord > h5{
    color: #02485E;
font-size: 27px;
font-style: normal;
font-weight: 700;
margin-bottom: 25px;
text-wrap: balance;
  }

  .member_card_secord > p{
    color: #02485E;
font-size: 25px;
font-style: normal;
font-weight: 500;
margin-bottom: 25px;
  }

  .member_card_secord > h3{
    color: #02485E;
font-size: 27px;
font-style: normal;
font-weight: 700;
margin-bottom: 0px;
  }

  .member_card_third{
    margin-top: 100px;
  }

  .member_card_third > h6{
    color: #02485E;
font-size: 28px;
font-style: normal;
font-weight: 700;
text-align: center;
  }

  .member_card_third .carousel_testimonials{
    margin-top: 20px;
    /* background: red; */
  }

  .member_card_third .carousel_testimonials .carousel_testimonials_item{
    padding: 30px 0;
  }

  .member_card_third .carousel_testimonials .carousel-control-prev , .member_card_third .carousel_testimonials .carousel-control-next{
    display: none;
  }

  .member_card_third .carousel_testimonials .carousel-indicators {
    bottom: -49px;
  }

  .member_card_third .carousel_testimonials .carousel-indicators button {
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
    position: relative;
    background: #DFF9FA;
    border: 0;
  }

  .member_card_third .carousel_testimonials .carousel-indicators button.active::before {
    content: "";
    position: absolute;
    background: #02485E;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    left: 4px;
    top: 4px;
  }

  .member_card_third .carousel_testimonials .carousel_testimonials_item .carousel_testimonials_main{
    width: 60%;
    position: relative;
    left: 50%;
    transform: translate(-50%,0);
    border-radius: 25px;
border: 2px solid #246275;
background: #FFF;
box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.25);
padding: 15px 30px;
padding: 28px 30px;
display: flex;
align-items: center;
  }

  .carousel_testimonials_main > p {
    color: #000;
font-size: 17px;
font-style: normal;
font-weight: 500;
margin-bottom: 0;
text-align: left;
margin-left: 40px;
  }

  .carousel_testimonials_main > .img_main {
    width: max-content;
    position: relative;
  }

  .carousel_testimonials_main > .img_main > .img_div {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #D49797;
    overflow: hidden;
    position: relative;
  }

  .carousel_testimonials_main > .img_main > span{
    position: absolute;
    right: -11px;
    top: 49px;
    width: 40px;
    height: 39px;
    background: #C6ECF4;
    border-radius: 50%;
    color: #02485E;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }


  .carousel_testimonials_main > .img_main > .img_div > img {
    width: 100%;
    height: 88%;
    position: relative;
    top: 5px;
  }



/* ----------------------------------------------  e-medicine section css ---------------------------------------------------------- */

.e-medicine_main{
    background: #B9F4FC;
    height: 355px;
}

.e-medicine_row{
    padding: 0;
    height: 100%;
    width: 100%;
    margin: auto;
}

.e-medicine_col{
    padding: 0;
    height: 100%;
}

.e-medicine_left_side{
    height: 100%;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    overflow: hidden;
}

.e-medicine_left_side > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
}

.e-medicine_right_Side{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: flex-start;
    height: 100%;
    padding: 20px 40px;
    padding: 20px 40px;
    padding-top: 57px;
    width: 90%;
}

.e-medicine_right_Side > h6 {
    color: #02485E;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 0;
    text-align: left;
    margin-bottom: 30px;
    line-height: 38px;
}

.e-medicine_right_Side > p {
    color: #02485E;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0;
    text-align: left;
    margin-bottom: 0px;
}


/* ----------------------------------------------  experienced doctor section css ---------------------------------------------------------- */

.experienced_doctor_main{
    height: max-content;
    /* background: url('./components/assets/images/experiencd_doctor_main.svg'); */
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding-top: 100px;
    /* padding-bottom: 80px; */
    position: relative;
    background: linear-gradient(106deg, #C2F8FF -5.91%, #50ABB7 97.46%);
box-shadow: 0px -15px 124px 0px rgba(2, 145, 154, 0.30) inset;
}

.experienced_doctor_main::before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    width: 700px;
    background: url('./components/assets/images/doctor_bottom_image.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
}

.experienced_doctor_main .experienced_doctor_content  > h6 {
    color: #02485E;
font-size: 20px;
font-style: normal;
font-weight: 500;
letter-spacing: 2px;
text-align: center;
margin-bottom: 30px;
}

.experienced_doctor_main .experienced_doctor_content  > h4 {
    color: #02485E;
font-size: 40px;
font-style: normal;
font-weight: 700;
text-align: center;
}

.carousel_experienced_doctor{
    /* margin-top: 50px; */
    /* margin-bottom: 100px; */
    width: 90%;
    margin: 50px auto 100px auto;
}

.react-multi-carousel-track {
    padding-bottom: 80px !important;
}

.carousel_experienced_doctor .carousel-control-prev , .carousel_experienced_doctor .carousel-control-next {
    display: none;
}

.carousel_experienced_doctor > .carousel-inner{
    padding-top: 80px;
    padding-bottom: 100px;
}

.carousel_experienced_doctor_main{
    display: flex;
    width: 80%;
    margin: auto;
    justify-content: space-between;
}

.carousel_experienced_doctor_main .experienced_doctor_card{
    width: 339px;
    height: 395px;
    border-radius: 25px;
    background: #63bac596;
    /* padding: 0px 11px; */
    position: relative;
}

.carousel_experienced_doctor_main .experienced_doctor_card .img_div{
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.carousel_experienced_doctor_main .experienced_doctor_card .img_div > img{
    width: 100%;
    object-fit: contain;
    object-position: top;
    position: absolute;
    bottom: 4px;
    left: 1px;
    /* transition: all 0.3s ease-in-out; */
    /* height: 110%; */
    height: 96%;
}

/* .carousel_experienced_doctor_main .experienced_doctor_card:hover .img_div > img {
    height: 114%;
    width: 104%;
    transition: all 0.3s ease-in-out;
} */

.carousel_experienced_doctor_main .experienced_doctor_card .carousel_experienced_doctor_content {
    border-radius: 0px 0px 25px 25px;
background: linear-gradient(96deg, rgba(255, 255, 255, 0.50) 1.06%, rgba(103, 189, 226, 0.70) 96.49%);
backdrop-filter: blur(3px);
position: absolute;
bottom: 0;
width: 100%;
padding: 18px 10px;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
}

.carousel_experienced_doctor_main .experienced_doctor_card .carousel_experienced_doctor_content > h5 {
    color: #02485E;
font-size: 22px;
font-style: normal;
font-weight: 500;
margin-bottom: 7px;
}

.carousel_experienced_doctor_main .experienced_doctor_card .carousel_experienced_doctor_content > p {
    color: #02485E;
font-size: 17px;
font-style: normal;
font-weight: 400;
margin-bottom: 0;
}

.carousel_experienced_doctor .react-multi-carousel-dot-list {
    position: absolute;
    bottom: 0px;
}

.carousel_experienced_doctor .react-multi-carousel-dot-list .react-multi-carousel-dot{
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
    position: relative;
    background: #DFF9FA;
    border: 0;
    margin: 0px 3px;
  }

  .carousel_experienced_doctor .react-multi-carousel-dot-list .react-multi-carousel-dot button{
    background: transparent;
    border: none;
    position: absolute;
    background: transparent;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    left: 4px;
    top: 4px;
  }

  .carousel_experienced_doctor .react-multi-carousel-dot-list .react-multi-carousel-dot--active::before {
    content: "";
    position: absolute;
    background: #02485E;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    left: 4px;
    top: 4px;
  }

  


/* ---------------------------------------------- footer section css  ---------------------------------------------------------- */

.footer_main{
    height: max-content;
    background: transparent;
    position: relative;
    padding-bottom: 3px;
}

.footer_main::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(91deg, rgba(255, 255, 255, 0.35) 0%, rgba(103, 189, 226, 0.45) 100%);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(7px);
    opacity: .6;
}

.footer_main_content{
    width: 65%;
    margin: auto;
    display: flex;
    align-self: center;
    justify-content: center;
    height: 100%;
    padding-top: 130px;
    padding-bottom: 50px;
    border-bottom: 2px solid #528A9B;
    gap: 5%;
    z-index: 10;
    position: relative;
}

.footer_main_content .footer_left {
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 10;
}

.footer_main_content .footer_right {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 10;
}

.footer_main_content .footer_left > h6 , .footer_main_content .footer_right > h6 {
    color: #02485E;
font-size: 25px;
font-style: normal;
font-weight: 600;
letter-spacing: 2px;
margin-bottom: 30px;
}

.footer_main_content .footer_left > ul {
    padding: 0;
}

.footer_main_content .footer_left > ul > li {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
}

.footer_main_content .footer_left > ul > li span{
    color: #02485E;
font-size: 17px;
font-style: normal;
font-weight: 400;
}

.footer_main_content .footer_left > ul > li .footer_icons{
    color: #02485E;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    width: 27px;
    min-width: 27px;
    max-width: 27px;
    height: 27px;
    min-height: 27px;
    max-height: 27px;
    padding: 4px;
    border: 2px solid #02485e;
    border-radius: 50%;
    margin-right: 20px;

}

.footer_right > ul {
    display: flex;
    padding: 0;
}

.footer_right > ul > li {
    margin-right: 20px;
}

.footer_right > ul > li > .social_icon_img{
    width: 36px;
    height: 36px;
    display: inline-block;
}

.footer_right > ul > li > .social_icon_img > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: all 0.3s linear;
}

.footer_right > ul > li > .social_icon_img:hover > img {
    filter: drop-shadow(1px 1px 3px #00000082);
    transition: all 0.3s linear;
}

.footer_main .copy_rights{
    color: #02485E;
font-size: 15px;
font-style: normal;
font-weight: 600;
z-index: 10;
position: relative;
text-align: center;
margin: 40px 0;
}


/* ----------------------------------------------  contact us section css ---------------------------------------------------------- */


.constact_us_main{
    /* background: url('../../assets/images/contactus_image.svg'); */
    /* height: calc(100vh - 0px); */
    /* background-size: cover; */
    /* background-repeat: no-repeat; */
    /* background-position: bottom; */
    position: relative;
}

.contact_background_image{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

/* .constact_us_main::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.30);
    width: 100%;
    height: 100%;
    z-index: 10;
} */

.contact_content_main{
    position: relative;
    height: 100%;
    width: 88%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 111px;
    padding-bottom: 101px;

}

.constact_us_main > .contact_content_main > h5 {
    position: relative;
    color: #246275;
    font-weight: 600;
    font-style: normal;
    font-family: var(--Poppins);
    font-size:30px;
    z-index: 10;
    margin-bottom: 25px;
    line-height: 35px;
}

.constact_us_main > .contact_content_main > form{
    position: relative;
    z-index: 10;
    width: 100%;
    /* margin: auto; */
    border-radius: 32px;
background: linear-gradient(106deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.30) 100%);
backdrop-filter: blur(10.5px);
padding: 60px 55px 50px 55px;
/* height: calc(85% - 60px); */
height: max-content;
}

.constact_us_main > .contact_content_main > form::-webkit-scrollbar{
    display: none;
}

.input_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.input_row > input{
    width: 47.5%;
    border-radius: 10px;
    border: 2px solid #02485E;
    background: transparent;
    color: rgba(2, 72, 94, 0.64);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    /* line-height: normal; */
    text-align: left;
    padding: 10px 25px 10px 25px;
    margin-bottom: 30px;
}

.input_row > input::placeholder{
    color: rgba(2, 72, 94, 0.64);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    /* line-height: normal; */
    text-align: left;
}

.constact_us_main > .contact_content_main > form > button{
    border-radius: 10px;
background: #4C8494;
    font-size: 20px;
    font-weight: 700;
    font-style: normal;
    color: #CBF9FF;
    padding: 9px 30px 11px 30px;
    border: none;
    display: block;
    margin: auto;
    /* background: #b1f1fa; */
}


/* ---------------------------------------------- qr section page ---------------------------------------------------------- */

.qr_main_div{
    background: linear-gradient(116deg, #C2F8FF 3.81%, #50ABB7 90.93%);
    height: calc(100vh - 115px);
padding:50px 0 ;
  }

  .qr_row_main {
    height: calc(100% - 30px);
    width: 85%;
    margin: auto;
    align-items: center;
    justify-content: center;
  }
  
  .qr_col_main {
    height: 100%;
    vertical-align: middle;
    /* margin: auto; */
    display: flex;
  }

 .qr_main_div > h6{
    color: #02485E;
font-family: var(--Poppins);
font-size: 30px;
font-style: normal;
font-weight: 600;
text-align: center;
 }

 .qr_row_main .qr_col_main .qr_left {
    height: 100%;
 }

 .qr_row_main .qr_col_main .qr_left > img {
    height: 100%;
    margin: auto;
    display: block;
    padding-top: 35px;
 }

 .qr_right{
    border-radius: 35px;
    background: linear-gradient(118deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.30) 102.04%);
    backdrop-filter: blur(15px);
    height: max-content;
    width: max-content;
    /* padding: 25px 61px; */
    width: 350px;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin: auto;
 }

 .qr_right > h6 {
    color: #02485E;
font-family: var(--Poppins);
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 20px;
margin-bottom: 0;
 }

 .qr_right > img {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
    margin-top: 20px;
 }

 .qrApplicationLink{
    text-decoration: none;
    color: inherit;
 }



/* ---------------------------------------------- media query ---------------------------------------------------------- */

@media(max-width:1400px){
    .member_card{
        width: 35%;
    }

    .carousel_experienced_doctor_main {
        width: 94%;
        justify-content: center;
        gap: 30px;
    }
}

@media(max-width:1280px){
    .member_card{
        width: 45%;
    }
}

@media(max-width:1200px){

    .header_main_section .offcanvas-body ul > li{
        margin: 0px 15px;
    }

    .Stethoscope_left > .img_div , .Stethoscope_left > .img_div > img{
        width: 100%;
        height: max-content;
    }

    .aboutUs_right > .img_div > .quation_card {
        bottom: -190px;
    left: -167px;
    }

    .carousel_item_main .carousel_main_left > h5 {
        font-size: 45px;
        line-height: 66px;
        width: 95%;
        text-align: left;
    }

    .carousel_item_main{
        height: 550px;
    }

    .moblie_application_right > img{
        top: 0;
    }
    
    /* .moblie_application_secondrow .moblie_application_right > img {
        position: absolute;
        bottom: 150px;
        top: initial;
        left: 40%;

    } */

    .workFlow_path > .flow_item:nth-child(odd)::after{
        top: 65%;
    }

    .workFlow_path > .flow_item:nth-child(even)::after{
        top: 65% !important;
    }

    .workFlow_path > .flow_item:last-child::after{
        top: initial !important;
    }

    .e-medicine_main {
        height: 400px;
    }
        
    .carousel_experienced_doctor_main {
        width: 94%;
        justify-content: center;
        gap: 30px;
    }

    .carousel_experienced_doctor_main .experienced_doctor_card {
        width: 301px;
        height: 376px;
    }

    .footer_main_content{
        width: 85%;
    }
    
}

@media(max-width:1100px){
    .member_card{
        width: 75%;
        margin: 0 30px;
    }
    .member_card_first{
        width: 100%;
    }
}

@media(max-width:992px) {

    .header_main_section .offcanvas-body ul > li{
        margin: 0px 10px;
    }

    .header_main_section .offcanvas-body>ul>li>a {
        font-size: 20px;
    }

    .header_main_section .offcanvas-body ul {
        padding-left: 0;
        padding-right: 0 !important;
    }

    .header_main_section .logo {
        width: 300px;
        height: 115px;
    }

    /* .moblie_application_secondrow .moblie_application_right > img {
        position: absolute;
        bottom: 0;
        top: initial;
        left: 41%;
        height: 100%;
        object-fit: contain;
    } */

    .moblie_application_left{
        padding-left: 50px;
    }

    .carousel_item_main .carousel_main_left > h5{
        font-size: 43px;
        margin-bottom: 40px;
        line-height: 55px;
        width: 95%;
    }

    .carousel_item_main .carousel_main_left{
        padding-left: 40px;
    }

    .aboutUs_right , .aboutUs_right > .img_div , .aboutUs_right > .img_div > img{
        height: 100%;
        justify-content: center;
    }

    .aboutUs_right > .img_div > .quation_card {
        bottom: -230px;
        left: -255px;
    }

    .video_main {
        margin-top: 330px;
    }
        
    .Homekit_right , .smart_kisok_right{
        padding-right: 30px;
    }

    .smart_watch_left{
        padding-left: 30px;
    }

    .our_service_main{
        padding: 40px;
    }

    .our_service_row{
        height: 450px;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .our_service_row h6{
        font-size: 28px;
    }

    .our_service_row p {
        font-size: 25px;
        line-height: 38px;
    }

    .Stethoscope_row{
        padding-top: 70px;
        padding-bottom: 70px !important;
        padding-bottom: 0;
    }

    .Stethoscope_right{
        padding-right: 30px ;
        padding-left: 30px ;
    }
    
    .Otoscope_row , .blood_oxygen_row , .blood_pressure_row , .ecg_row{
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .Otoscope_row::before , .blood_oxygen_row::before , .blood_pressure_row::before , .ecg_row::before{
        background-position: right;
    }

    .Otoscope_left , .blood_oxygen_left , .blood_pressure_left , .ecg_left{
        width: 100%;
    }

    .Otoscope_right > .img_div , .blood_oxygen_right > .img_div , .blood_pressure_right > .img_div , .ecg_right > .img_div{
        width: 100%;
        height: max-content;
    }

    .Otoscope_right::before , .blood_oxygen_right::before , .blood_pressure_right::before , .ecg_right::before{
        width: 150px;
    }

    .Otoscope_right > .img_div > img , .blood_oxygen_right > .img_div > img , .ecg_right > .img_div > img {
        width: 100%;
    }

    .blood_pressure_right > .img_div > img {
        width: 85%;
    }

    .body_temperature_right , .glucometer_right , .height_weight_right{
        right: 0;
        width: 100%;
        padding-right: 0px;
        padding-left: 0px;
    }

    .glucometer_left > .img_div > img {
        object-fit: contain;
        object-position: center;
    }

    .height_weight_left > .img_div > img {
        object-fit: contain;
        object-position: bottom;
    }

    .qr_row_main {
        width: 95%;
    }

    .qr_row_main .qr_col_main .qr_left > img{
        width: 100%;
    }

    .qr_right {
        width: 330px;
        height: 330px;
    }

    
.workFlow_path > .flow_item {
    width: 75%;
}

.workFlow_path > .flow_item:nth-child(odd)::after{
    right: 93px;
    width: 20px;
    height: 168px;
    top: 100%;
    z-index: -2;
    background: transparent;
    border-right: 4px dashed #02485e;
}

.workFlow_path > .flow_item:nth-child(even)::after{
    left: 80px;
    width: 20px;
    height: 168px;
    top: 100% !important;
    z-index: -2;
    background: transparent;
    border-right: 4px dashed #02485e;
}

.workFlow_path > .flow_item:last-child::after{
    background: url('./components/assets/images/flow_dotted_4.svg') !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    width: 154px;
    left: 123px;
    bottom: -262px;
    top: initial;
    height: 264px;
    border: none;
}

.member_card_main{
    flex-wrap: wrap;
}

.member_card {
    width: 51%;
    margin: 20px 0;
}

.footer_main_content{
    width: 90%;
}

.e-medicine_left_side > img{
    object-position: center;
}

.e-medicine_main {
    height: max-content;
    padding: 90px 0 70px 0;
}

.e-medicine_left_side{
    width: 70%;
    height: 333px;
}

.e-medicine_right_Side{
    width: 100%;
}

.carousel_experienced_doctor_main .experienced_doctor_card {
    width: 271px;
    height: 370px;
}

.experienced_doctor_main .experienced_doctor_content > h4{
    width: 90%;
    margin: auto;
}

.member_card_third .carousel_testimonials .carousel_testimonials_item .carousel_testimonials_main{
    width: 85%;
}

/* .mobile_arrow_image{
    width: 90% !important;
} */

.height_weight_left > .img_div {
    height: 600px;
}
    
}

@media (min-width:768px) and (max-width:991px){

    .constact_us_main > .contact_content_main > form {
        overflow-y: auto;
        height: max-content;
    }

    
.contact_content_main{
    justify-content: center;
    padding-top: 0px;
}

.height_weight_left > .img_div , .ecg_right > .img_div{
    height: 500px;
}

.carousel_experienced_doctor{
    width: 97%;
}

}

@media(max-width:767.98px) {

    .header_main_section .offcanvas-body>ul>li>a {
        transition: none;
    }

    .header_main_section .logo {
        width: 306px;
        height: 95px;
        margin-right: auto;
    }

    .header_main_section .logo > img {
        width: initial;
        height: 100%;
    }

    .moblie_application_row,.moblie_application_secondrow {
        flex-direction: column-reverse;
        margin: auto;
    }

    .moblie_application_right > img{
        width: 350px;
        margin: 0px auto;
    }

    .moblie_main_title {
        margin-bottom: 30px !important;
    }

    .moblie_main_description{
        margin-bottom: 10px !important;
    }
    
    .moblie_main_title , .moblie_main_description{
        width: 95%;
        margin: auto;
    }

    .moblie_application_left , .moblie_application_secondrow .moblie_application_left{
        padding-right: 25px;
        padding-left: 25px;
    }

    .moblie_application_secondrow .moblie_application_left{
        margin-top: 20px;
    }

    .carousel_main_right {
        display: none;
    }

    .carousel_item_main {
        height: 450px;
    }

    .carousel_item_main .carousel_main_left > h5 {
        line-height: 72px;
    }

    .aboutUs_row {
        flex-direction: column-reverse;
    }

    .aboutUs_right > .img_div {
        flex-direction: column;
        padding-top: 0px;
        /* padding-top: 50px; */
        padding-bottom: 50px;
    }

    .aboutUs_right > .img_div > img {
        width: 85%;
        margin: auto;
    }

    .aboutUs_left{
        padding-left: 25px;
        padding-right: 25px;
    }

    .aboutUs_right > .img_div > .quation_card {
        bottom: 0;
        left: 0;
        position: relative;
        margin: auto;
        margin-bottom: 70px;
        width: 85%;
        margin-top: 35px;
    }

    .video_main {
        margin-top: 30px;
    }

    .smart_watch_row ,.Kiosk_row {
        flex-direction: column-reverse;
        padding-bottom: 70px;
    }

    .smart_kisok_left > .img_div , .Homekit_left > .img_div {
        height: max-content;
    }

    .smart_watch_right > .img_div , .smart_kisok_left > .img_div , .Homekit_left > .img_div , .Kiosk_right > .img_div {
        width: 470px;
        margin: auto;
        margin-bottom: 36px;
    }

    .smart_watch_left , .smart_kisok_right , .Homekit_right ,.Kiosk_left {
        padding-left: 25px;
        padding-right: 25px;
    }

    .smart_watch_col , .smart_kisok_col , .homekit_col , .kiosk_col{
        padding: 0;
    }
    

    .our_service_row{
        height: max-content;
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .our_service_row h6{
        font-size: 25px;
    }

    .our_service_row p {
        font-size: 22px;
        line-height: 35px;
    }

    .our_service_main {
        padding: 24px;
        width: 90%;
    }

    .our_service_row::before{
        width: 315px;
    }

    .Otoscope_row , .blood_oxygen_row , .blood_pressure_row , .ecg_row {
        flex-direction: column-reverse;
        padding-top: 111px;
    }

    .Otoscope_right::before , .blood_oxygen_right::before , .blood_pressure_right::before , .ecg_right::before {
        display: none;
    }

    .body_temperature_row , .Stethoscope_row , .glucometer_row , .height_weight_row {
        padding-top: 111px;
        padding-bottom: 55px;
    }

    .body_temperature_col , .otoscope_col , .Stethoscope_col , .blood_oxygen_col , .glucometer_col , .blood_pressure_col , .ecg_col , .height_weight_col{
        padding: 0;
    }

    .Otoscope_right , .blood_oxygen_right , .blood_pressure_right {
        background: #57a7b9;
        border-top-left-radius: 45px;
        border-bottom-left-radius: 45px;
        width: 72%;
        margin-left: auto;
        /* padding: 33px 0px 33px 33px; */
        padding: 20px 0 20px 20px;
        overflow: hidden;
    }

    .ecg_right {
        background: #57a7b9;
        border-top-left-radius: 45px;
        border-bottom-left-radius: 45px;
        width: 72%;
        margin-left: auto;
        padding: 0;
        overflow: hidden;
    }

    .Stethoscope_left > .img_div{
        bottom: 0;
        justify-content: center;
        align-items: center;
    }

    .body_temperature_left > .img_div > img {
        width: 100%;
        height: 400px;
        object-fit: contain;
    }

    .body_temperature_left , .Stethoscope_left , .glucometer_left , .height_weight_left{
        background: #667aad;
        border-top-right-radius: 45px;
        border-bottom-right-radius: 45px;
        width: 72%;
        overflow: hidden;
    }

    .body_temperature_row::before , .Otoscope_row::before , .Stethoscope_row::before , .blood_oxygen_row::before , .glucometer_row::before , .blood_pressure_row::before , .ecg_row::before , .height_weight_row::before {
        display: none;
    }

    .body_temperature_right , .Otoscope_left , .Stethoscope_right , .blood_oxygen_left , .glucometer_right , .blood_pressure_left , .ecg_left , .height_weight_right{
        right: 0;
        width: 100%;
        padding-right: 25px;
        padding-left: 25px;
        margin-top: 50px;
    }

    .body_temperature_left > .img_div , .Otoscope_right > .img_div , .Stethoscope_left > .img_div , .blood_oxygen_right > .img_div , .glucometer_left > .img_div , .blood_pressure_right > .img_div , .ecg_right > .img_div , .height_weight_left > .img_div {
        width: 100%;
        /* background: #667aad; */
        margin-left: auto;
        overflow: hidden;
        height: 400px;
    }

    .constact_us_main > .contact_content_main > form {
        overflow-y: auto;
        /* height: max-content; */
        padding: 30px 30px;
        height: calc(85% - 60px);
    }

    .input_row {
        flex-direction: column;
    }

    .input_row > input {
        width: 100%;
        margin-bottom: 25px;
    }

    .qr_row_main{
        width: 100%;
    }

    .qr_main_div > h6 {
        width: 90%;
        margin: auto;
    }

    .height_weight_left > .img_div , .ecg_right > .img_div{
        height: 400px;
    }


    .height_weight_right , .glucometer_right{
        padding-bottom: 40px;
    }


    .member_card_first > h5{
        line-height: initial;
        width: 90%;
        margin: auto;
        margin-top: 100px;
    }

    .member_card {
        width: 75%;
        margin: 20px 0;
    }

    .experienced_doctor_main::before{
        width: 100%;
    }

    .footer_main_content{
        width: 90%;
        gap: 0%;
        flex-direction: column;
        padding-top: 70px;
        padding-bottom: 50px;
        padding-left: 13px;
        padding-right: 13px;
    }

    .footer_main_content .footer_left{
        width: 100%;
    }

    .footer_main_content .footer_right{
        width: 100%;
    }

    .footer_right > ul {
        display: flex;
        padding: 0;
        flex-wrap: wrap;
    }

    .footer_right > ul > li {
        margin-right: 17px;
        margin-bottom: 22px;
    }

    .carousel_experienced_doctor_main{
        flex-wrap: wrap;
    }

    .carousel_experienced_doctor > .carousel-inner {
        padding-top: 0px;
        padding-bottom: 100px;
    }

    .carousel_experienced_doctor_main .experienced_doctor_card {
        width: 271px;
        height: 330px;
        margin-top: 0px;
    }

    .carousel_experienced_doctor{
        width: 100%;
    }

    .contact_background_image{
        display: none;
    }

    .constact_us_main{
        background: linear-gradient(116deg, #C2F8FF 3.81%, #50ABB7 90.93%);
    }

    .Stethoscope_row > .Stethoscope_col:first-child {
        margin-bottom: 50px;
    }

    .glucometer_left{
        width: 60%;
    }
}

@media(max-width:576px) {

    .moblie_application_right > img{
        width: 300px;
        margin: 0px auto;
    }

    .moblie_main_title{
        font-size: 30px;
        width: 90%;
        margin: auto;
        line-height: 45px;
        margin-bottom: 20px;
    }

    .moblie_application_right {
        height: 440px;
    }

    .moblie_application_left > h6{
        font-size: 30px;
    }

    .mobile_para_long {
        font-size: 20px !important;
        /* text-indent: 20px; */
        /* text-align: justify; */
        width: 100%;
    }

    .moblie_main_description{
        width: 90%;
        margin: auto;
        font-size: 20px;
    }

    .carousel_item_main .carousel_main_left{
        padding-left: 30px;
    }

    .carousel_item_main{
        height: 455px;
    }

    .carousel_item_main .carousel_main_left > h5 {
        font-size: 39px;
        line-height: normal;
    }

    .aboutUs_right > .img_div > img{
        width: 95%;
    }

    .aboutUs_right > .img_div{
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .aboutUs_right > .img_div > .quation_card{
        padding: 30px 20px;
    }

    .aboutUs_right > .img_div > .quation_card > ul > li{
        font-size: 15px;
    }

    .aboutUs_left > h3{
        font-size: 30px;
        line-height: 45px;
    }

    .aboutUs_left > p.description_long , .aboutUs_left > p.description_short {
        font-size: 20px;
        /* text-indent: 59px;
        text-align: justify; */
        width: 100%;
    }

    .smart_watch_right > .img_div ,.smart_kisok_left > .img_div , .Homekit_left > .img_div , .Kiosk_right > .img_div {
        width: 320px;
    }

    .smart_watch_left > h6 , .smart_kisok_right > h6 , .Homekit_right > h6 , .Kiosk_left > h6{
        font-size: 30px;
    }

    .smart_watch_left > p.para_long , .smart_kisok_right > p.para_long , .Homekit_right > p.para_long , .Kiosk_left > p.para_long , .Kiosk_left > p.para_short{
        font-size: 20px;
        /* text-indent: 59px;
        text-align: justify; */
    }
    
    .body_temperature_left ,.Otoscope_right , .Stethoscope_left , .blood_oxygen_right , .glucometer_left , .blood_pressure_right , .ecg_right , .height_weight_left {
        width: 85%;
        overflow: hidden;
    }

    .body_temperature_right > h6 , .Otoscope_left > h6 , .Stethoscope_right > h6, .blood_oxygen_left > h6 , .glucometer_right > h6 , .blood_pressure_left > h6 , .ecg_left > h6 , .height_weight_right > h6 {
        font-size: 30px;
    }

    .body_temperature_right > p.para_long , .Otoscope_left > p.para_long , .Stethoscope_right > p.para_long, .blood_oxygen_left > p.para_long , .glucometer_right > p.para_long , .blood_pressure_left > p.para_long , .ecg_left > p.para_long , .height_weight_right > p.para_long{
        font-size: 20px;
        /* text-indent: 59px;
        text-align: justify; */
    }

    .qr_main_div{
        height: 100%;
    }

    .qr_row_main .qr_col_main .qr_left {
        display: block;
        width: 100%;
        margin: 20px 0 70px 0;
    }

    .qr_row_main .qr_col_main .qr_left > img{
        width: 300px;
        margin: auto;
        display: block;
    }

    .workFlow_path > .flow_item {
        width: 100%;
        flex-direction: column;
        gap: 17px;
    }

    .flow_item > .flow_content_main{
        margin-left: 0;
    }

    .workFlow_path > .flow_item:last-child::after{
        right: 42px;
        left: initial;
    }

    .workFlow_main {
        padding: 80px 28px;
    }

    .member_card {
        width: 100%;
        margin: 20px 0;
        flex-wrap: wrap;
        height: max-content;
        justify-content: center;
        flex-direction: column;
    }

    .member_card > .member_card_content{
        margin-top: 30px;
        margin-bottom: 20px;
        margin-left: 0;
    }

    .carousel_experienced_doctor{
        margin-bottom: 50px;
    }

    .member_card_main {
        padding: 0 30px;
        margin-top: 30px;
    }

    .e-medicine_left_side{
        width: 90%;
        height: 257px;
        object-position: center;
    }

    .member_card_third .carousel_testimonials .carousel_testimonials_item .carousel_testimonials_main{
        flex-wrap: wrap;
    }

    .carousel_testimonials_main > p{
        margin-top: 30px;
        margin-left: 0;
    }

    .workFlow_main > h5 , .member_card_first > h5{
        font-size: 27px;
    }

    .workFlow_path , .member_card_third{
        margin-top: 50px;
    }

    .member_card_secord{
        margin-top: 80px;
        padding: 50px 10px;
    }

    .footer_main .copy_rights{
        width: 90%;
        margin: 40px auto;
    }

    .member_card_secord > h5{
        font-size: 23px;
    }

    .member_card_secord > p , .member_card_secord > h3{
        font-size: 20px;
        width: 100%;
    }

    .experienced_doctor_main .experienced_doctor_content > h4{
        font-size: 30px;
    }

    .carousel_experienced_doctor_main .experienced_doctor_card .carousel_experienced_doctor_content > h5{
        font-size: 19px;
    }

    .carousel_experienced_doctor_main .experienced_doctor_card .carousel_experienced_doctor_content > p{
        font-size: 15px;
    }

    .footer_main_content .footer_left > h6, .footer_main_content .footer_right > h6{
        font-size: 25px;
    }

    .member_card > img {
        height: 150px;
        transition: none;
    }

    .member_card:hover > img {
        height: 150px;
    }

    .offcanvas_header.offcanvas.offcanvas-end.show{
        width: 80%;
        max-width: 80%;
        z-index: 2000;
    }

    .page-header.is-sticky{
        position: sticky !important;
        top: 0 !important;
        animation: none !important;
        transition: none !important;
        backdrop-filter: none !important;
        width: 100% !important;
        z-index: 500 !important;
    }

    #root{
        overflow-x: hidden !important;
    }
    
}

@media(max-width:450px){
    
    .member_card > .member_card_content > h6{
        font-size: 20px;
    }  
    
}

@media(max-width:420px){
    .header_main_section .logo {
        width: 200px;
        height: 86px;
        margin-right: auto;
    }
    .header_main_section {
        padding-left: 0 !important;
    }    
    
}

@media(max-width:400px){

    .qr_right {
        width: 90%;
        height: 350px;
    }

    .qr_main_div > h6 {
        line-height: 46px;
    }

      
  .qr_col_main {
    padding: 0;
  }

 }

/* input:invalid{
    border: 2px solid red;
} */

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.60) 0%, !important;
    -webkit-text-fill-color: #02485E !important;
    caret-color: black !important;
}

.scroll_button_top{
    z-index: 5000 !important;
    background-color: #b1f1fa !important;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.577) !important;
    padding: 7px !important;
}

.scroll_button_top .svg_icon_Top{
    transform: rotate(180deg);
    position: relative;
    bottom: 3px;
}