/* 


.constact_us_main{
    height: calc(100vh - 0px);
    position: relative;
}

.contact_background_image{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.constact_us_main::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.30);
    width: 100%;
    height: 100%;
    z-index: 10;
}

.contact_content_main{
    position: relative;
    height: 100%;
    width: 88%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 60px;

}

.constact_us_main > .contact_content_main > h5 {
    position: relative;
    color: #B1F1FA;
    font-weight: 600;
    font-style: normal;
    font-family: var(--Poppins);
    font-size:30px;
    z-index: 10;
    margin-bottom: 25px;
    line-height: 35px;
}

.constact_us_main > .contact_content_main > form{
    position: relative;
    z-index: 10;
    width: 100%;
    border-radius: 32px;
background: linear-gradient(106deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.30) 100%);
backdrop-filter: blur(10.5px);
padding: 60px 55px 50px 55px;
height: max-content;
}

.constact_us_main > .contact_content_main > form::-webkit-scrollbar{
    display: none;
}

.input_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.input_row > input{
    width: 47.5%;
    border-radius: 10px;
    border: 2px solid #02485E;
    background: transparent;
    color: rgba(2, 72, 94, 0.64);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    text-align: left;
    padding: 10px 25px 10px 25px;
    margin-bottom: 30px;
}

.input_row > input::placeholder{
    color: rgba(2, 72, 94, 0.64);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    text-align: left;
}

.constact_us_main > .contact_content_main > form > button{
    border-radius: 10px;
    background: linear-gradient(270deg, #37D5C1 0%, #32BAEC 100%);
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    color: #000;
    padding: 9px 30px 11px 30px;
    border: none;
    display: block;
    margin: auto;
    background: #b1f1fa;
}



@media (min-width:768px) and (max-width:991px){

    .constact_us_main > .contact_content_main > form {
        overflow-y: auto;
        height: max-content;
    }

    
.contact_content_main{
    justify-content: center;
    padding-top: 0px;
}

}


@media(max-width:767px){

    .constact_us_main > .contact_content_main > form {
        overflow-y: auto;
        padding: 30px 30px;
        height: calc(85% - 60px);
    }

    .input_row {
        flex-direction: column;
    }

    .input_row > input {
        width: 100%;
        margin-bottom: 25px;
    }
}
 */

 :root {
    --Poppins: "Poppins", sans-serif !important;
  }

  .qr_main_div{
    background: linear-gradient(116deg, #C2F8FF 3.81%, #50ABB7 90.93%);
    height: calc(100vh - 115px);
padding:50px 0 ;
  }

  .qr_row_main {
    height: calc(100% - 30px);
    width: 85%;
    margin: auto;
    align-items: center;
    justify-content: center;
  }
  
  .qr_col_main {
    height: 100%;
    vertical-align: middle;
    /* margin: auto; */
    display: flex;
  }

 .qr_main_div > h6{
    color: #02485E;
font-family: var(--Poppins);
font-size: 30px;
font-style: normal;
font-weight: 600;
text-align: center;
 }

 .qr_row_main .qr_col_main .qr_left {
    height: 100%;
 }

 .qr_row_main .qr_col_main .qr_left > img {
    height: 100%;
    margin: auto;
    display: block;
    padding-top: 35px;
 }

 .qr_right{
    border-radius: 35px;
    background: linear-gradient(118deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.30) 102.04%);
    backdrop-filter: blur(15px);
    height: max-content;
    width: max-content;
    /* padding: 25px 61px; */
    width: 350px;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin: auto;
 }

 .qr_right > h6 {
    color: #02485E;
font-family: var(--Poppins);
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 20px;
margin-bottom: 0;
 }

 .qr_right > img {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
    margin-top: 20px;
 }

 @media(max-width:992px){
    .qr_row_main {
        width: 95%;
    }

    .qr_row_main .qr_col_main .qr_left > img{
        width: 100%;
    }

    .qr_right {
        width: 330px;
        height: 330px;
    }

 }

 @media (max-width:767px){

    .qr_row_main{
        width: 100%;
    }

    .qr_main_div > h6 {
        width: 90%;
        margin: auto;
    }
 }

 @media(max-width:576px){

    .qr_main_div{
        height: 100%;
    }

    .qr_row_main .qr_col_main .qr_left {
        display: block;
        width: 100%;
        margin: 20px 0 70px 0;
    }

    .qr_row_main .qr_col_main .qr_left > img{
        width: 300px;
        margin: auto;
        display: block;
    }
 }

 @media(max-width:400px){

    .qr_right {
        width: 90%;
        height: 350px;
    }

    .qr_main_div > h6 {
        line-height: 46px;
    }

      
  .qr_col_main {
    padding: 0;
  }

 }